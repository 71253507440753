import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  additionalHelmetIcon,
  bike,
  coupanIcon,
  helmetIcon,
  LocationIcon,
  starIcon,
} from "../../../../assets";
import { colors } from "../../../../themes/colors";
import {
  getArraySum,
  getDeliveryAddress,
  getFormattedDate,
  getFormattedINR,
  getTimestampAtStartOfDay,
  setLocalStorageData,
} from "../../../../utills/helper";
import {
  ChargeTypes,
  ChargeTypesDisplayName,
  helmetsDisplay,
  helmetsDisplayName,
  helmetTypes,
  LocalStorage,
  PaymentStatus,
  TypographyConstants,
  YorPlanTabKeys,
} from "../../../../constant/enum";
import { useDispatch, useSelector } from "react-redux";
import en from "../../../../locales/en.json";
import { CustomButton } from "../../../components/atom/Buttons/CustomButtons";
import SideDrawer from "../../../components/template/SideDrawer/SideDrawer";
import Helmetbadge from "../../../components/template/HelmetBadge";
import {
  calculateCharges,
  clearOrderData,
  getAllCoupons,
  getPaymentStatus,
  storeBooking,
} from "../../../redux/actions/yorActions";
import { Routes } from "../../../../constant/routeContants";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../redux/reducers";
import { RAZORPAY_KEY, WEB_URL } from "../../../../config/apiUrls";
import CustomHoverPopover from "../../../components/molecule/PopOver/HoverPopover";
import InfoIcon from "@mui/icons-material/Info";
import Input from "../../../components/atom/InputFields/Input";
import { checkAlfaNumeric, REGEX } from "../../../../utills/regex";
import CustomCard from "../../../components/template/CustomCard";
import style from "../planDetails.module.css";
import { Height } from "@mui/icons-material";
import PremiumHelmetIcon from "../../../../assets/images/svgComponents/PremiumHelmetIcon";
import FullFaceHelmetIcon from "../../../../assets/images/svgComponents/FullFaceHelmetIcon";
import HalfFaceHelmetIcon from "../../../../assets/images/svgComponents/HalfFaceHelmetIcon";
import KidsHelmetIcon from "../../../../assets/images/svgComponents/KidsHelmetIcon";

const Style = { borderBottom: "1px solid #ECECEC", padding: "8px" };

const helmetIcons = {
  PREMIUM: <PremiumHelmetIcon />,
  FULL_FACE: <FullFaceHelmetIcon />,
  HALF_FACE: <HalfFaceHelmetIcon />,
  KIDS: <KidsHelmetIcon />,
};
const HeadingValue = ({
  heading,
  value,
  style,
  headingStyle,
  valueStyle,
  icon,
  description = "",
  valueDescription = "",
}: any) => {
  return (
    <Box sx={{ display: "flex", ...style }}>
      <Box>
        <Typography
          sx={{ color: colors.YOR_text_gray, width: "170px", ...headingStyle }}
        >
          {heading}
        </Typography>
        {description && (
          <Typography
            variant={TypographyConstants.CAPTION}
            sx={{ color: colors.THEME_BLUE }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography sx={{ ...valueStyle }}>{value}</Typography>
        {valueDescription && (
          <Typography
            variant={TypographyConstants.CAPTION}
            sx={{ color: colors.text_gray }}
          >
            {valueDescription}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const HeaderWithIcon = ({ icon, header, style }: any) => {
  return (
    <Box sx={{ display: "flex", marginBottom: "5px", ...style }}>
      <img src={icon} alt="" />
      <Typography
        variant={TypographyConstants.BODY1}
        color={colors.YOR_text_gray}
      >
        {header}
      </Typography>
    </Box>
  );
};

const CouponDrawer = ({
  fields,
  setFields,
  allCoupons,
  custId,
  loading,
}: any) => {
  const dispatch = useDispatch();
  const style = {
    fontSize: "12px",
    width: "140px",
  };

  const handleCouponApply = (coupon?: any) => {
    const payload = {
      data: {
        planId: fields?.selectedPlan?.id,
        customerId: custId,
        couponCode: coupon ? coupon : fields?.couponCode,
        helmets: {
          premium: fields.helmets.PREMIUM,
          halfFace: fields.helmets.HALF_FACE,
          fullFace: fields.helmets.FULL_FACE,
          kids: fields.helmets.KIDS,
        },
      },
    };
    setFields((prev: any) => ({
      ...prev,
      selectedCoupon: coupon ? coupon : fields?.couponCode,
    }));
    dispatch(calculateCharges(payload));
  };
  return (
    <Stack sx={{ padding: "15px", gap: "20px" }}>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Input
          onChange={(e: any) => {
            if (checkAlfaNumeric(e.target.value)) {
              setFields((prev) => ({
                ...prev,
                couponCode: e.target.value,
              }));
            }
          }}
          StartIcon={coupanIcon}
          value={fields.couponCode}
          placeholder={en.YORPlanDetails.applyCoupon}
          customStyle={{ borderRadius: "5px !important" }}
        />
        <CustomButton
          variant="outlined"
          label={en.YORPlanDetails.apply}
          disabled={loading || !fields?.couponCode?.length}
          sx={{ padding: "4px 15px", borderRadius: "5px", Height: "50px" }}
          onClick={() => {
            handleCouponApply();
          }}
        />
      </Box>
      {allCoupons?.couponCodes?.map((item: any) => {
        return (
          <CustomCard
            headerNotRequired={true}
            footerNotRequired={true}
            content={
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <HeadingValue
                    heading={en.YORPlanDetails.couponCode}
                    value={item?.code}
                    headingStyle={{ ...style }}
                    valueStyle={{
                      fontSize: "12px",
                      color: colors.THEME_BLUE,
                      fontWeight: 500,
                    }}
                  />
                  <HeadingValue
                    heading={en.YORPlanDetails.minCartAmount}
                    value={getFormattedINR(item?.eligibility?.minAmount)}
                    headingStyle={{ ...style }}
                    valueStyle={{ fontSize: "12px" }}
                  />
                  <Typography
                    variant={TypographyConstants.SUBHEADING}
                    sx={{ color: colors.THEME_BLUE }}
                  >{`Save Upto ${getFormattedINR(
                    item?.benefits[0]?.configuration?.maxValue
                  )} with ${item?.code} Coupon code`}</Typography>
                </Box>
                {fields?.selectedCoupon === item?.code ? (
                  <Chip
                    label="Applied"
                    sx={{
                      color: colors.white,
                      backgroundColor: colors.light_green,
                    }}
                  />
                ) : (
                  <CustomButton
                    label={en.YORPlanDetails.apply}
                    disabled={loading}
                    variant="outlined"
                    sx={{ minHeight: "20px", Height: "25px", padding: "0px" }}
                    headingStyle={{ fontSize: "12px" }}
                    onClick={() => {
                      handleCouponApply(item?.code);
                    }}
                  />
                )}
              </Box>
            }
          />
        );
      })}
    </Stack>
  );
};

function YORCheckoutTab({
  fields,
  setFields,
  additionalHelmets,
  errorFields,
  setErrorFields,
  handleTabChange,
  handleChange,
  images,
  imagePath,
  deliveryAddress,
}: any) {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [displayFreeHelmet, setDisplayFreeHelmet] = useState(false);

  const {
    planCharges,
    orderData,
    paymentStatus,
    paymentLoader,
    allCoupons,
    loading,
  } = useSelector((state: RootState) => state.yorReducer);
  const { userProfileDetail } = useSelector(
    (state: RootState) => state.profileReducer
  );

  const { id, first_name, email_id, mobile_number } = userProfileDetail;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //additional helmet count
  const extraHelmetHandler = (e: any, type: string, action: string) => {
    let helmetCountArray = Object.values(fields.helmets);
    let helmetCount = getArraySum(helmetCountArray);
    if (action === "INCREMENT") {
      if (helmetCount < 3) {
        setFields((prevState: any) => ({
          ...prevState,
          helmets: {
            ...prevState.helmets,
            [type]: prevState.helmets[type] + 1,
          },
        }));
        setErrorFields((prevState: any) => ({
          ...prevState,
          maxHelmets: "",
        }));
      } else {
        setErrorFields((prevState: any) => ({
          ...prevState,
          maxHelmets: en.errorMessages.maxHelmet,
        }));
      }
    }
    if (action === "DECREMENT") {
      setFields((prevState: any) => ({
        ...prevState,
        helmets: {
          ...prevState.helmets,
          [type]: prevState.helmets[type]
            ? prevState.helmets[type] - 1
            : prevState.helmets[type],
        },
      }));
      setErrorFields((prevState: any) => ({
        ...prevState,
        maxHelmets: "",
      }));
    }
  };

  //modal Close
  const handleModalClose = () => {
    setOpenModal((prev: any) => !prev);
  };

  //toggle Drawer
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //handle payment
  const handlePayment = () => {
    const payload = {
      data: {
        data: {
          planId: fields?.selectedPlan?.id,
          customerId: id,
          complementaryHelmet: fields?.complementaryHelmet,
          startDate: getTimestampAtStartOfDay(fields?.rideStartDate),
          accessories: {
            helmets: {
              premium: fields.helmets.PREMIUM,
              halfFace: fields.helmets.HALF_FACE,
              fullFace: fields.helmets.FULL_FACE,
              kids: fields.helmets.KIDS,
            },
          },
        },
      },
      paymentFlow: "RAZORPAY_CHECKOUT",
    };
    if (fields?.selectedCoupon) {
      payload.data.data["couponCode"] = fields?.selectedCoupon;
    }
    dispatch(storeBooking(payload));
  };

  //load razorpay script
  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  //display Razorpay
  const displayRazorpay = async (orderid: any, amount: any, rzpKey: string) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else {
    }
    const options = {
      key: rzpKey ? rzpKey : RAZORPAY_KEY,
      // Enter the Key ID generated from the Dashboard
      // amount: parseInt(final_total),
      amount: parseInt(amount),
      currency: "INR",
      timeout: 300,
      name: "Freedo Rentals",
      order_id: orderid,
      description: "Credits towards Freedo Rentals",
      prefill: {
        name: first_name,
        email: email_id,
        contact: mobile_number,
      },
      theme: {
        color: "#2BC7C9",
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "All payment methods",
              instruments: [
                {
                  method: "upi",
                },
                {
                  method: "card",
                },
                {
                  method: "wallet",
                },
                {
                  method: "netbanking",
                },
                {
                  method: "emi",
                },
                {
                  method: "cardless_emi",
                },
              ],
            },
          },
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: false,
          },
        },
      },

      // image: { logo },
      handler: async function (response: any) {
        const reqPayload = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          //razorpay_signature: response.razorpay_signature,
        };
        dispatch(getPaymentStatus({ id: orderData?.paymentId }));
      },
    };
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  };

  //navigate to success or pending screen
  const paymentVerification = (response: any) => {
    if (response) {
      if (response.status == PaymentStatus.PENDING) {
        window.open(
          `${process.env.PUBLIC_URL}/payment-pending-web/${orderData.orderId}`
        );
      } else {
        setLocalStorageData(LocalStorage.bookingDetails, {
          state: { ...response, ...{ isYor: true } },
        });
        window.location.href = `/payment-success-response-handler/${orderData.orderId}`;
      }
    } else {
      console.log("PaymentFailure", {});
    }
  };
  //calculate charges on Change of additional helmet
  useEffect(() => {
    const payload = {
      data: {
        planId: fields?.selectedPlan?.id,
        customerId: id,
        helmets: {
          premium: fields.helmets.PREMIUM,
          halfFace: fields.helmets.HALF_FACE,
          fullFace: fields.helmets.FULL_FACE,
          kids: fields.helmets.KIDS,
        },
      },
    };
    dispatch(calculateCharges(payload));
  }, [fields?.helmets]);

  useEffect(() => {
    if (paymentStatus.status == PaymentStatus.SUCCESS) {
      paymentVerification(paymentStatus);
    } else if (paymentStatus.status == PaymentStatus.PENDING) {
      paymentVerification(paymentStatus);
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (Object.keys(orderData)?.length) {
      displayRazorpay(
        orderData?.pgDetails?.orderId,
        orderData?.amount,
        orderData?.pgConfig?.key
      );
    }
  }, [orderData]);

  useEffect(() => {
    if (planCharges?.closeModal) {
      toggleDrawer();
      setFields((prev) => ({ ...prev, couponCode: "" }));
    }
  }, [planCharges]);

  //set complementaryHelmet
  useEffect(() => {
    let planBenefits = fields?.selectedPlan?.benefits?.map((benefit: any) => {
      return benefit?.type;
    });
    const isHelmetFree = planBenefits?.includes("FREE_HELMET") ? true : false;
    setDisplayFreeHelmet(isHelmetFree);
    setFields((prev: any) => ({ ...prev, complementaryHelmet: isHelmetFree }));
    dispatch(
      getAllCoupons({
        customerId: id,
        platform: "CUSTOMER",
        type: "COUPON_CODE",
        lob: "R2O",
      })
    );
    window.scrollTo(0, 0);
    return () => {
      dispatch(clearOrderData());
    };
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Stack>
          <Typography
            variant={TypographyConstants.SUBTITLE2}
            sx={{ color: colors.secondary_gray }}
          >
            {en.yourOwnRide.vehicleDetails}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "6px" }}>
            <Box
              onClick={() => navigate(Routes.YOR_HOME_SCREEN)}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ color: colors.primary }}>
                {en.yourOwnRide.home}
              </Typography>
            </Box>
            <Box
              onClick={() => navigate(Routes.YOR_RENTING_FLEET)}
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ color: colors.primary }}>
                {en.yourOwnRide.allVehicles}
              </Typography>
            </Box>
            <Box
              onClick={() =>
                handleTabChange(0, YorPlanTabKeys.PLAN_DETAILS_TAB)
              }
              sx={{ cursor: "pointer" }}
            >
              <Typography sx={{ color: colors.primary }}>
                {en.yourOwnRide.planDetails}
              </Typography>
            </Box>
            <Typography>{en.yourOwnRide.vehicleCheckout}</Typography>
          </Box>
        </Stack>
        <Grid size={{ sm: 12, md: 8 }}>
          <Stack sx={{ gap: "20px" }}>
            <Grid container spacing={0}>
              <Grid
                size={{ sm: 12, md: 5 }}
                sx={{
                  border: "1px solid #ECECEC",
                  padding: "20px 60px",
                  width: "100%",
                }}
              >
                <img
                  src={`${WEB_URL}/${imagePath}/${images[0].type}-${images[0].fileName}`}
                  alt=""
                />
              </Grid>
              <Grid
                size={{ sm: 12, md: 7 }}
                sx={{ border: "1px solid #ECECEC", width: "100%" }}
              >
                <HeadingValue
                  heading={`${en.YORPlanDetails.selectedVehicle}:`}
                  value={fields?.selectedPlan?.modelDisplayName}
                  style={Style}
                />
                <HeadingValue
                  heading={`${en.YORPlanDetails.rideStartDate}:`}
                  value={getFormattedDate(fields?.rideStartDate, "date")}
                  style={Style}
                />
                <HeadingValue
                  heading={`${en.YORPlanDetails.rentalPeriod}:`}
                  value={`${fields?.selectedPlan?.durationInMonths} Months`}
                  style={Style}
                />
                <HeadingValue
                  heading={`${en.YORPlanDetails.planSelected}:`}
                  value={fields?.selectedPlan?.displayName}
                  style={Style}
                />
              </Grid>
            </Grid>
            {displayFreeHelmet ? (
              <Box>
                <Box sx={{ marginBottom: "5px" }}>
                  <HeaderWithIcon
                    icon={helmetIcon}
                    header={en.YORPlanDetails.ComplementaryHelmet}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box>
                    <CustomButton
                      variant={
                        fields.complementaryHelmet ? "contained" : "outlined"
                      }
                      label="Yes"
                      value={fields.complementaryHelmet}
                      width="107px"
                      onClick={() => handleChange("complementaryHelmet", true)}
                    />
                  </Box>
                  <Box>
                    <CustomButton
                      variant={
                        fields.complementaryHelmet ? "outlined" : "contained"
                      }
                      label="No"
                      value={fields.complementaryHelmet}
                      width="107px"
                      onClick={() => handleChange("complementaryHelmet", false)}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Box>
              <HeaderWithIcon
                icon={additionalHelmetIcon}
                header={en.YORPlanDetails.additionalHelmet}
                style={{ marginBottom: "10px" }}
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {additionalHelmets?.map((item) => {
                  return (
                    <Helmetbadge
                      title={helmetsDisplayName[item]}
                      type={item}
                      clickHandler={extraHelmetHandler}
                      helmetTypeCount={fields.helmets[item]}
                      width="140px"
                      helmetIcon={helmetIcons[item]}
                    />
                  );
                })}
              </Box>
              <Box>
                {errorFields?.maxHelmets && (
                  <Typography
                    variant={TypographyConstants.ERROR_MESSAGE1}
                    style={{ fontSize: "0.7rem" }}
                  >
                    {errorFields?.maxHelmets}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <HeaderWithIcon
                icon={LocationIcon}
                header={en.YORPlanDetails.deliveryDetails}
                style={{ marginBottom: "10px" }}
              />
              <CustomCard
                footerNotRequired={true}
                headerNotRequired={true}
                content={
                  <Stack>
                    <Typography
                      variant={TypographyConstants.BODY1}
                      color={colors.YOR_text_gray}
                    >
                      {en.YORPlanDetails.hubAddress}
                    </Typography>
                    <Typography>
                      {getDeliveryAddress(deliveryAddress) ?? "N/A"}
                    </Typography>
                    <Typography
                      variant={TypographyConstants.SUBHEADING}
                      sx={{ color: colors.THEME_BLUE, cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `https://maps.google.com/?q=${deliveryAddress?.locationData?.coordinates?.lat},${deliveryAddress?.locationData?.coordinates?.lng}`,
                          "_blank"
                        );
                      }}
                    >
                      {en.YORPlanDetails.viewOnMap}
                    </Typography>
                  </Stack>
                }
                contentStyle={{ padding: "20px" }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ sm: 12, md: 4 }}>
          {planCharges?.charges?.length ? (
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px dashed #CFCFCF",
                  padding: "12px 16px",
                }}
              >
                <Typography>Promocode and Coupon</Typography>
                <Typography
                  color={colors.YOR_THEME_BLUE}
                  onClick={toggleDrawer}
                  sx={{ cursor: "pointer" }}
                >
                  Apply
                </Typography>
              </Box>
              <Box>
                <HeaderWithIcon
                  icon={starIcon}
                  header={en.YORPlanDetails.paymentBreakup}
                />
                <Box sx={{ border: "1px solid #ECECEC" }}>
                  {planCharges?.charges?.map((item: any) => {
                    if (Object.keys(ChargeTypes)?.includes(item?.name)) {
                      if (item?.name === ChargeTypes.ACCESSORY && item.amount) {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              borderBottom: "1px solid #ECECEC",
                              padding: "8px",
                            }}
                          >
                            <Box sx={{ display: "flex", width: "170px" }}>
                              <Typography
                                sx={{
                                  color: colors.YOR_text_gray,
                                  paddingRight: "10px",
                                }}
                              >
                                {ChargeTypesDisplayName[item?.name]}
                              </Typography>
                              {Object.keys(item?.breakUp?.helmets)?.length ? (
                                <CustomHoverPopover
                                  popupContent={
                                    <Stack sx={{ padding: "10px 20px" }}>
                                      {Object.keys(item?.breakUp?.helmets)?.map(
                                        (helmet: any, index: any) => {
                                          return (
                                            <HeadingValue
                                              heading={`${
                                                helmetsDisplay[helmet]
                                              } x ${
                                                fields?.helmets[
                                                  helmetTypes[helmet]
                                                ]
                                              }`}
                                              value={getFormattedINR(
                                                item?.breakUp?.helmets[helmet]
                                              )}
                                            />
                                          );
                                        }
                                      )}
                                    </Stack>
                                  }
                                  hoverContent={
                                    <>
                                      <InfoIcon
                                        fontSize="small"
                                        color="primary"
                                      />
                                    </>
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Typography>
                              {getFormattedINR(item?.amount)}
                            </Typography>
                          </Box>
                        );
                      } else {
                        if (item?.name !== ChargeTypes.ACCESSORY) {
                          return (
                            <HeadingValue
                              heading={`${ChargeTypesDisplayName[item?.name]}:`}
                              value={
                                item?.discount
                                  ? getFormattedINR(
                                      item?.amount - item.discount
                                    )
                                  : getFormattedINR(item?.amount)
                              }
                              description={
                                item?.name === ChargeTypes.MONTHLY_FEE
                                  ? `Recurring for ${planCharges?.durationInMonths} months`
                                  : item?.name === ChargeTypes.SUBSCRIPTION_FEE
                                  ? en.YORPlanDetails.enrollmentFee
                                  : ""
                              }
                              valueDescription={
                                item?.name === ChargeTypes.SUBSCRIPTION_FEE
                                  ? en.YORPlanDetails.inclusiveTaxes
                                  : ""
                              }
                              style={Style}
                            />
                          );
                        }
                      }
                    }
                  })}
                  {planCharges?.totalDiscount ? (
                    <HeadingValue
                      heading={`${en.YORPlanDetails.offerApplied}:`}
                      value={`-${getFormattedINR(planCharges?.totalDiscount)}`}
                      style={Style}
                      headingStyle={{ color: colors.light_green }}
                      valueStyle={{ color: colors.light_green }}
                    />
                  ) : (
                    <></>
                  )}
                  <HeadingValue
                    heading={`${en.YORPlanDetails.FinalPrice}:`}
                    value={getFormattedINR(planCharges?.totalFee)}
                    style={Style}
                    headingStyle={{ color: colors.YOR_THEME_BLUE }}
                    valueStyle={{ color: colors.YOR_THEME_BLUE }}
                  />
                </Box>
              </Box>
              <Box>
                <CustomButton
                  label={en.YORPlanDetails.payNow}
                  loading={paymentLoader}
                  variant="contained"
                  onClick={handlePayment}
                  style={{ width: "100%", borderRadius: "30px" }}
                />
              </Box>
              <Box>
                <Typography
                  variant={TypographyConstants.CAPTION}
                  sx={{ color: colors.YOR_text_gray }}
                >
                  By clicking on pay now you are accepting our{" "}
                  <span
                    style={{
                      color: colors.THEME_BLUE,
                      fontSize: "12px",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(Routes.TERMS_CONDITIONS, "_blank")
                    }
                  >
                    Terms & Conditions
                  </span>
                </Typography>
              </Box>
            </Stack>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <SideDrawer
        open={open}
        heading={"Offer and Coupons"}
        toggleDrawer={toggleDrawer}
        disablePadding={true}
        headerPadding={"1vw 1.5vw"}
        renderUI={
          <CouponDrawer
            fields={fields}
            setFields={setFields}
            allCoupons={allCoupons}
            custId={id}
            loading={loading}
          />
        }
      />
    </Box>
  );
}

export default YORCheckoutTab;
