import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useRef, useState } from "react";
import { colors } from "../../../../themes/colors";
import {
  awardIcon,
  bike,
  BikeIcon,
  EngineIcon,
  FuelIcon,
  listItemIcon,
  PowerIcon,
  scooterIcon,
  starIcon,
} from "../../../../assets";
import DatePickerInput from "../../../../component/DatePicker/DatePickerInput";
import { getFormattedDate, getFormattedINR } from "../../../../utills/helper";
import styles from "../planDetails.module.css";
import en from "../../../../locales/en.json";
import {
  BenefitTypesDisplay,
  BenifitType,
  planDetailsKeys,
  TypographyConstants,
  VehicleTypeDisplay,
} from "../../../../constant/enum";
import { CustomButton } from "../../../components/atom/Buttons/CustomButtons";
import CustomCard from "../../../components/template/CustomCard";
import HeadingValueTypography from "../../../components/template/HeadingValueTypography";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../constant/routeContants";
import { WEB_URL } from "../../../../config/apiUrls";
import CustomCarousel from "./CustomCarousel";

//Bike specification UI
const BikeSpecifications = ({
  specifications,
  modelName,
  imagePath,
  images,
  vehicleType,
  manufacturer,
}: any) => {
  const style = { border: "1px solid #ECECEC", padding: "12px" };
  return (
    <Box>
      <HeaderWithIcon
        icon={starIcon}
        header={en.YORPlanDetails.bikeSpecification}
      />
      <Grid container spacing={0}>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={scooterIcon}
            heading={en.YORPlanDetails.type}
            value={VehicleTypeDisplay[vehicleType?.name]}
            iconStyle={{ paddingLeft: "0px" }}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={awardIcon}
            heading={en.YORPlanDetails.brand}
            value={VehicleTypeDisplay[manufacturer]}
            iconStyle={{ paddingLeft: "0px" }}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={BikeIcon}
            iconStyle={{ height: "17px", paddingLeft: "0px" }}
            heading={en.YORPlanDetails.model}
            value={modelName}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={FuelIcon}
            heading={en.YORPlanDetails.consumption}
            value={specifications?.averageEconomy}
            iconStyle={{ paddingLeft: "0px" }}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={PowerIcon}
            heading={en.YORPlanDetails.power}
            value={specifications?.hp}
            iconStyle={{ paddingLeft: "0px" }}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }} sx={style}>
          <HeadingValueTypography
            icon={EngineIcon}
            heading={en.YORPlanDetails.CC}
            value={specifications?.cubicCapacity}
            iconStyle={{ paddingLeft: "0px" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const HeadingValue = ({
  heading,
  value,
  style,
  headingStyle,
  valueStyle,
}: any) => {
  return (
    <Box className={styles.headingValueStyle}>
      <Typography
        sx={{ color: colors.YOR_text_gray, width: "162px", ...headingStyle }}
      >
        {heading}
      </Typography>
      <Typography sx={{ ...valueStyle }}>{value}</Typography>
    </Box>
  );
};

const HeaderWithIcon = ({ icon, header, style }: any) => {
  return (
    <Box sx={{ display: "flex", marginBottom: "5px", ...style }}>
      <img src={icon} alt="" />
      <Typography
        variant={TypographyConstants.BODY1}
        color={colors.YOR_text_gray}
      >
        {header}
      </Typography>
    </Box>
  );
};

const PlanDetailsTab = ({
  fields,
  setFields,
  handleTabChange,
  RentalsPlans,
  durationInMonths,
  filteredPlan,
  handleFields,
  modelDetails,
  modelName,
  imagePath,
  images,
  vehicleType,
  manufacturer,
}: any) => {
  const navigate = useNavigate();
  //set selected plan
  const onCheckboxChange = (plan: any) => {
    setFields({
      ...fields,
      selectedPlan: plan,
      helmets: {
        PREMIUM: 0,
        HALF_FACE: 0,
        FULL_FACE: 0,
        KIDS: 0,
      },
    });
  };
  //carousel Item
  const CarouselItem = (props: any) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={`${WEB_URL}/${imagePath}/${props.item?.type}-${props.item?.fileName}`}
          alt=""
        />
      </Box>
    );
  };

  //plan Charges list
  const planChargesKeyValues = (data: any) =>
    Object?.keys(planDetailsKeys)?.map((key: any, index: number) => {
      return (
        <ListItem className={styles.planChargesList}>
          <Typography sx={{ color: colors.text_gray }}>
            {planDetailsKeys[key]}
          </Typography>
          <Typography>{getFormattedINR(data[key])}</Typography>
        </ListItem>
      );
    });

  return (
    <Box>
      <Stack>
        <Typography
          variant={TypographyConstants.SUBTITLE2}
          sx={{ color: colors.secondary_gray }}
        >
          {en.yourOwnRide.vehicleDetails}
        </Typography>
        <Box sx={{ display: "flex", gap: "6px" }}>
          <Box
            onClick={() => navigate(Routes.YOR_HOME_SCREEN)}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: colors.primary }}>
              {en.yourOwnRide.home}
            </Typography>
          </Box>
          <Box
            onClick={() => navigate(Routes.YOR_RENTING_FLEET)}
            sx={{ cursor: "pointer" }}
          >
            <Typography sx={{ color: colors.primary }}>
              {en.yourOwnRide.allVehicles}
            </Typography>
          </Box>
          <Typography>{en.yourOwnRide.planDetails}</Typography>
        </Box>
      </Stack>
      <Grid container spacing={2}>
        <Grid size={{ sm: 12, md: 7 }}>
          <Stack spacing={2} direction="column">
            <Box className={styles.topCarousel}>
              <CustomCarousel items={images} Item={CarouselItem} />
            </Box>
            <Box>
              <Typography
                variant={TypographyConstants.CAPTION}
                sx={{ color: colors.red, fontWeight: 600 }}
              >
                {manufacturer}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant={TypographyConstants.SUBTITLE2}
                  sx={{ lineHeight: 0.57 }}
                >
                  {modelName}
                </Typography>
                <Typography
                  variant={TypographyConstants.SUBTITLE2}
                  sx={{ color: colors.YOR_THEME_BLUE, lineHeight: 0.57 }}
                >
                  {`${getFormattedINR(
                    fields?.selectedPlan?.planCharges?.monthlyCharges
                  )}/month`}
                </Typography>
              </Box>
            </Box>
            <BikeSpecifications
              specifications={modelDetails}
              modelName={modelName}
              imagePath={imagePath}
              images={images}
              vehicleType={vehicleType}
              manufacturer={manufacturer}
            />
            <Box>
              <HeaderWithIcon
                icon={starIcon}
                header={en.YORPlanDetails.rideStartDate}
              />
              <Box className={styles.rideStartDate}>
                <Typography>
                  {getFormattedDate(fields?.rideStartDate, "date")}
                </Typography>
                <Typography
                  onClick={() => {
                    navigate(Routes.YOR_RENTING_FLEET);
                  }}
                  sx={{ color: colors.YOR_THEME_BLUE, cursor: "pointer" }}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            {filteredPlan?.length ? (
              <>
                <Box>
                  <HeaderWithIcon
                    icon={starIcon}
                    header={en.YORPlanDetails.selectRentalPeriod}
                  />
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {durationInMonths?.map((item: any) => {
                      return (
                        <CustomButton
                          variant={
                            fields.selectedRentalPeriod == item
                              ? "contained"
                              : "outlined"
                          }
                          label={`${item} Months`}
                          style={{
                            borderRadius: "30px",
                            padding: "7px 20px",
                          }}
                          onClick={() =>
                            handleFields("selectedRentalPeriod", item)
                          }
                        />
                      );
                    })}
                  </Box>
                  <Typography
                    typography="caption"
                    sx={{ color: colors.YOR_text_gray }}
                  >
                    Ride will end on{" "}
                    {getFormattedDate(filteredPlan[0]?.endDate, "date")}
                  </Typography>
                </Box>
                <Box>
                  <HeaderWithIcon
                    icon={starIcon}
                    header={en.YORPlanDetails.chooseAvailabelPlans}
                  />
                  <Grid container spacing={2}>
                    {filteredPlan?.map((plan: any) => {
                      return (
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <CustomCard
                            title={plan?.displayName}
                            subtitle={`${plan?.durationInMonths} Months`}
                            showCheckbox={true}
                            content={
                              <Box>
                                {plan?.benefits?.length ? (
                                  plan?.benefits?.map((item: any) => {
                                    if (
                                      ((item?.type ===
                                        BenifitType.FREE_MAINTAINANCE ||
                                        item?.type ===
                                          BenifitType.FREE_INSURANCE) &&
                                        item?.data?.isActive) ||
                                      (item?.type === BenifitType.FREE_HELMET &&
                                        item?.data?.helmetType)
                                    ) {
                                      return (
                                        <List
                                          sx={{
                                            border: "1px solid  #ECECEC",
                                          }}
                                        >
                                          <ListItem
                                            sx={{ padding: "0px 16px" }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                color: colors.text_gray,
                                              }}
                                            >
                                              <img src={listItemIcon} alt="" />
                                              {BenefitTypesDisplay[item?.type]}
                                            </Box>
                                          </ListItem>
                                        </List>
                                      );
                                    }
                                  })
                                ) : (
                                  <></>
                                )}
                                <List
                                  sx={{
                                    border: "1px solid  #ECECEC",
                                    borderTop: 0,
                                  }}
                                >
                                  {planChargesKeyValues(plan?.planCharges)}
                                </List>
                              </Box>
                            }
                            contentStyle={{ padding: "0px" }}
                            footerContent={
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{ color: colors.YOR_THEME_BLUE }}
                                >
                                  Total Charges
                                </Typography>
                                <Typography
                                  sx={{ color: colors.YOR_THEME_BLUE }}
                                >
                                  {getFormattedINR(
                                    plan?.planCharges?.totalAmount
                                  )}
                                </Typography>
                              </Box>
                            }
                            footerStyle={{ padding: "8px 16px" }}
                            onCheckboxChange={() => onCheckboxChange(plan)}
                            checkboxChecked={fields?.selectedPlan?.id}
                            data={plan}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
        <Grid size={{ sm: 12, md: 5 }} width="100%">
          <Stack spacing={2} direction="column" sx={{ marginTop: "20px" }}>
            <Box className={styles.carousel}>
              <CustomCarousel items={images} Item={CarouselItem} />
            </Box>
            {filteredPlan?.length ? (
              <>
                <Box>
                  <HeaderWithIcon
                    icon={starIcon}
                    header={en.YORPlanDetails.orderSummary}
                  />
                  <Stack
                    direction="column"
                    sx={{ border: "1px solid #ECECEC" }}
                  >
                    <HeadingValue
                      heading={`${en.YORPlanDetails.yorSubscription}:`}
                      value={getFormattedINR(
                        fields?.selectedPlan?.planCharges?.depositAmount
                      )}
                    />
                    <HeadingValue
                      heading={`${en.YORPlanDetails.monthlySubscription}:`}
                      value={getFormattedINR(
                        fields?.selectedPlan?.planCharges?.monthlyCharges
                      )}
                    />
                    <HeadingValue
                      heading={`${en.YORPlanDetails.FinalPrice}:`}
                      value={getFormattedINR(
                        fields?.selectedPlan?.planCharges?.totalAmount -
                          fields?.selectedPlan?.planCharges?.closureAmount
                      )}
                      headingStyle={{ color: colors.YOR_THEME_BLUE }}
                      valueStyle={{ color: colors.YOR_THEME_BLUE }}
                    />
                  </Stack>
                </Box>
                <CustomButton
                  label={en.YORPlanDetails.confirmAndNext}
                  variant="contained"
                  onClick={() => {
                    handleTabChange(1, "CheckOutTab");
                  }}
                  style={{ width: "100%", borderRadius: "30px" }}
                />
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlanDetailsTab;
