import {
  Box,
  Card,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Carousel from "react-material-ui-carousel";
import {
  CrossIcon,
  LocationIcon,
  NotAvailableIcon,
  StartDateCalendarIcon,
  SubscriptionBanner,
  TickIcon,
  XplulseImage,
} from "../../../assets";
import Styles from "./index.module.css";

import { Fragment, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cityStatus,
  LocalStorage,
  SessionStorage,
  TypographyConstants,
} from "../../../constant/enum";
import { Routes } from "../../../constant/routeContants";
import en from "../../../locales/en.json";
import { RootState } from "../../../redux/store";

import { Helmet } from "react-helmet";
import { colors } from "../../../themes/colors";
import {
  divideArrayTo2D,
  getFormattedDate,
  getLocalStorageData,
  getSessionStorage,
  setSessionStorage,
} from "../../../utills/helper";
import {
  CustomButton,
  CustomIconButton,
} from "../../components/atom/Buttons/CustomButtons";
import CustomAccordion from "../../components/molecule/Accordion/customAccordion";
import CustomReactDateTimePicker from "../../components/molecule/DatePicker/CustomReactDateTimePicker";
import CustomSearchLayout from "../../components/molecule/UiComponents/CustomSearchLayout";
import {
  getCityV2,
  getYorCmsData,
  setUserCityYor,
} from "../../redux/actions/yorActions";
import CustomerReviewCard from "./sections/customerReviewCard";
import SelectCityModalYor from "./sections/selectCityModalYor";
import { YORHeadingContent } from "./sections/yorCommonSections";

const {
  yourOwnRide,
  home: { homePageCarousal },
} = en;

const iconsSection5: { readonly [key: string]: ReactNode } = {
  yes: <img src={TickIcon} />,
  no: <img src={CrossIcon} />,
  na: <img src={NotAvailableIcon} />,
};

const OfferCard = ({ data }: any) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{ maxWidth: { xs: "280px", sm: "290px" }, boxShadow: "none" }}
        className={Styles.subscriptionOfferCards}
      >
        <img
          style={{ width: "64px", height: "40px", objectFit: "contain" }}
          src={data.image}
          alt="icon"
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderRight: "2px dashed #66666633",
          }}
        />

        <Stack
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Tooltip title={data.heading}>
              <Typography
                sx={{
                  color: colors.dark_gray_secondary,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "126px",
                  cursor: "pointer",
                }}
                variant={TypographyConstants.SUBTITLE1}
              >
                {data.heading}
              </Typography>
            </Tooltip>
            <Tooltip title={data.subHeading}>
              <Typography
                sx={{
                  color: colors.dark_gray_secondary,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "126px",
                  cursor: "pointer",
                  fontWeight: 500,
                }}
                variant={TypographyConstants.SMALL_TEXT}
              >
                {data.subHeading}
              </Typography>
            </Tooltip>
          </Stack>
          <Typography
            sx={{
              color: "#0000004D",
            }}
            variant={TypographyConstants.SMALL_TEXT}
          >
            {data.expiryText}
          </Typography>
        </Stack>
      </Card>
      <Box className={Styles.roundCutLeft}></Box>
      <Box className={Styles.roundCutRight}></Box>
    </Box>
  );
};

const YorHomescreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get selected user city from local storage
  const yorCityData = getLocalStorageData(LocalStorage.yorUserCity);

  // get start ride date from session storage
  const startRideDate = getSessionStorage<{ startDate: string }>(
    SessionStorage.yorStartRide
  );

  // data from yor reducer
  const {
    cityV2 = [],
    userCityYor,
    yorCmsData,
  } = useSelector((state: RootState) => state.yorReducer);

  // sate to handle city and date modal
  const [showCityModal, setShowCityModal] = useState(false);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showStartDateMobile, setShowStartDateMobile] = useState(false);

  // state to store ride start date
  const [startDate, setStartDate] = useState<any>({
    date: null,
    displayStartDate: "Start Ride Date",
  });

  useEffect(() => {
    setTimeout(() => {
      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);
  }, []);

  useEffect(() => {
    // api call to get data from cms
    dispatch(getYorCmsData());

    dispatch(getCityV2({ page: 1, limit: 100, status: cityStatus.ACTIVE }));
    if (yorCityData) dispatch(setUserCityYor(yorCityData));

    if (startRideDate?.startDate) {
      // checking date store in session is less than current date
      const startDate: Date =
        new Date(startRideDate?.startDate).getTime() < new Date().getTime()
          ? new Date()
          : new Date(startRideDate?.startDate);

      setStartDate({
        date: startDate,
        displayStartDate: getFormattedDate(startDate, "date"),
      });
    }
  }, []);

  useEffect(() => {
    if (cityV2.length && !yorCityData) {
      setShowCityModal(true);
    }
  }, [cityV2]);

  // handle calendar cuty modal
  const cityModalToggleHandler = () => {
    setShowCityModal((prev) => !prev);
  };

  // handle calendar toggle
  const toggleStartDateCalendar = () => {
    setShowStartDate(!showStartDate);
  };

  // handle calendar toggle mobile view
  const toggleStartDateCalendarMobile = () => {
    setShowStartDateMobile(!showStartDateMobile);
  };

  // start ride date select handler
  const startDateChangeHandler = (date: any) => {
    setStartDate({
      date: date,
      displayStartDate: getFormattedDate(date, "date"),
    });
    toggleStartDateCalendar();
    toggleStartDateCalendarMobile();
    setSessionStorage(SessionStorage.yorStartRide, { startDate: date });
  };

  //success callback on fetching available vehicles data
  const onViewVehicle = () => {
    navigate(Routes.YOR_RENTING_FLEET);
  };

  // handle city click in city modal
  const handleCityClick = (index: number) => {
    dispatch(setUserCityYor(cityV2[index]));
    cityModalToggleHandler();
  };

  // crousel card city date selection
  const formFieldsContent = (
    <>
      <Stack>
        <CustomIconButton
          onClick={() => {
            cityModalToggleHandler();
          }}
        >
          <img
            src={LocationIcon}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography
            variant={TypographyConstants.BODY1}
            color={colors.dark_cyan}
          >
            {userCityYor?.displayName
              ? userCityYor?.displayName
              : yourOwnRide.locateMe}
          </Typography>
        </CustomIconButton>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{
          borderColor: colors.secondary_gray,
          height: "40px",
          borderWidth: 1,
        }}
      />

      {/* Start date */}
      <Stack>
        <CustomIconButton
          onClick={() => {
            setShowStartDate(true);
          }}
        >
          <img
            src={StartDateCalendarIcon}
            height="1rem"
            style={{ height: "15px", width: "25px" }}
          />
          <Typography
            variant={TypographyConstants.BODY1}
            sx={{
              color: colors.secondary_gray,
              padding: "0px 7px",
            }}
          >
            {startDate?.displayStartDate}
          </Typography>
        </CustomIconButton>
      </Stack>
    </>
  );

  let maxDate: any = new Date();

  // yor crousel array
  let yorCrousel: JSX.Element[] = [
    <Box>
      <Card
        className={Styles.yorCrouselOne}
        style={{
          backgroundImage: `url(${
            yorCmsData?.section1?.length
              ? yorCmsData?.section1[0]?.backgroundImage
              : SubscriptionBanner
          })`,
          borderRadius: "0px",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <Box className={Styles.yorCarouselBox} sx={{ position: "relative" }}>
          <Stack gap={"25px"}>
            <Stack>
              <Typography
                sx={{
                  fontWeight: 900,
                  fontSize: "30px",
                  background:
                    "-webkit-linear-gradient(0deg, #2BC7C9, #2BC7C9),-webkit-linear-gradient(0deg, #305D61, #305D61)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  lineHeight: "32px",
                }}
              >
                {yorCmsData?.section1?.length
                  ? yorCmsData?.section1[0]?.title
                  : ""}
              </Typography>
              <Box className={Styles.poweredbyFreedo}>
                <Typography
                  variant={TypographyConstants.SUBHEADING}
                  sx={{
                    background:
                      "-webkit-linear-gradient(90.39deg, #305D61 21.34%, #2BC7C9 78.66%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {yorCmsData?.section1?.length
                    ? yorCmsData?.section1[0]?.subHeading
                    : ""}
                </Typography>
                <Box className={Styles.yorCarouselBoxTitleContentVector}></Box>
              </Box>
            </Stack>
            <Typography
              variant={TypographyConstants.BODY1}
              sx={{
                color: colors.dark_grayish_red,
              }}
            >
              {yorCmsData?.section1?.length
                ? yorCmsData?.section1[0]?.description
                : ""}
            </Typography>

            <CustomSearchLayout
              formFields={formFieldsContent}
              actionButton={
                <CustomButton
                  label={yourOwnRide.searchVehicles}
                  onClick={onViewVehicle}
                  variant="contained"
                  disabled={!(yorCityData?.name && startDate?.date)}
                />
              }
              isBackgroundStyle={false}
            />
          </Stack>

          {/* Start Ride Date Picker */}
          {showStartDate && (
            <Box
              display={{ xs: "none", sm: "none", md: "flex" }}
              sx={{ position: "absolute", top: "6px", left: "224px" }}
            >
              <CustomReactDateTimePicker
                selectedDate={startDate?.date}
                dateChangeHandler={startDateChangeHandler}
                toggleCalendarHandler={toggleStartDateCalendar}
                showTimeSelect={false}
                minDate={new Date()}
                maxDate={maxDate.setDate(maxDate.getDate() + 180)}
              />
            </Box>
          )}
        </Box>
      </Card>
    </Box>,
  ];

  return (
    <Fragment>
      {/* SEO ranking */}
      <Helmet>
        <title>YOR Subscription: Your Bike, One Fee, Zero Hassles</title>
        <meta
          name="title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          name="description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta name="url" content="https://freedo.rentals/yor" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/yor" />
        <meta
          property="og:title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          property="og:description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta property="twitter:url" content="https://freedo.rentals/yor" />
        <meta
          property="twitter:title"
          content="YOR Subscription: Your Bike, One Fee, Zero Hassles"
        />
        <meta
          property="twitter:description"
          content="Ride worry-free with YOR Subscription! For a fixed monthly fee that covers your bike’s cost, insurance, and maintenance. No long-term commitments—just effortless, hassle-free riding!"
        />
        <meta name="googlebot" content="index" />
        <link rel="canonical" href="https://freedo.rentals/yor" />
      </Helmet>

      {/* screen Ui */}
      <Stack gap={"20px"}>
        {/* crousels yor for desktop view*/}
        <Carousel
          animation="slide"
          duration={5000}
          indicatorContainerProps={{
            className: Styles.yorCarouselIndicatorsContainer,
          }}
          activeIndicatorIconButtonProps={{
            style: { color: colors.primary, top: "-1px" },
          }}
          indicatorIconButtonProps={{
            style: { color: colors.light_gray_secondary },
          }}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {yorCrousel.map((item, i) => (
            <>{item}</>
          ))}
        </Carousel>

        {/* mobile view crousel */}
        <Stack
          display={{ sm: "flex", md: "none" }}
          className={Styles.mobileViewCrousel}
        >
          <Typography
            sx={{
              fontWeight: 900,
              fontSize: "30px",
              background:
                "-webkit-linear-gradient(0deg, #2BC7C9, #2BC7C9),-webkit-linear-gradient(0deg, #305D61, #305D61)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              lineHeight: "32px",
              textAlign: "center",
            }}
          >
            {yourOwnRide?.yorHeading}
          </Typography>

          <Divider
            orientation="horizontal"
            flexItem
            sx={{ borderColor: colors.secondary_green }}
          >
            <Typography
              variant={TypographyConstants.SUBHEADING}
              sx={{
                background:
                  "-webkit-linear-gradient(90.39deg, #305D61 21.34%, #2BC7C9 78.66%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {yourOwnRide?.poweredbyFreedo}
            </Typography>
          </Divider>

          <Typography
            variant={TypographyConstants.BODY1}
            sx={{
              color: colors.dark_grayish_red,
              textAlign: "center",
            }}
          >
            {yourOwnRide?.crouselCardContent}
          </Typography>

          <Stack
            className={Styles.mobileViewDateCityPicker}
            sx={{ padding: { xs: "10px", sm: "20px 40px" } }}
          >
            <CustomIconButton
              onClick={() => {
                cityModalToggleHandler();
              }}
              buttonSx={{
                width: "100%",
                position: "relative",
                maxWidth: "210px",
                border: "1px solid",
                borderColor: colors.grayish_cyan,
                padding: "9px",
              }}
            >
              <img
                src={LocationIcon}
                height="1rem"
                style={{ height: "15px", width: "25px" }}
              />
              <Typography
                variant={TypographyConstants.BODY1}
                color={colors.dark_cyan}
              >
                {userCityYor?.displayName
                  ? userCityYor?.displayName
                  : yourOwnRide.locateMe}
              </Typography>
            </CustomIconButton>

            <CustomIconButton
              onClick={() => {
                setShowStartDateMobile(true);
              }}
              buttonSx={{
                width: "100%",
                position: "relative",
                maxWidth: "210px",
                border: "1px solid",
                borderColor: colors.grayish_cyan,
                padding: "10px",
              }}
            >
              <img
                src={StartDateCalendarIcon}
                height="1rem"
                style={{ height: "15px", width: "25px" }}
              />
              <Typography
                variant={TypographyConstants.BODY1}
                sx={{
                  color: colors.secondary_gray,
                  padding: "0px 7px",
                }}
              >
                {startDate?.displayStartDate}
              </Typography>
            </CustomIconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CustomButton
                label={yourOwnRide.searchVehicles}
                variant="contained"
                wrapperStyles={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                sx={{ maxWidth: "210px" }}
                onClick={onViewVehicle}
                disabled={!(yorCityData?.name && startDate?.date)}
              />
            </Box>

            {/* Start Ride Date Picker */}
            {showStartDateMobile && (
              <Box
                display={{ xs: "flex", sm: "flex", md: "none" }}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CustomReactDateTimePicker
                  selectedDate={startDate?.date}
                  dateChangeHandler={startDateChangeHandler}
                  toggleCalendarHandler={toggleStartDateCalendarMobile}
                  showTimeSelect={false}
                  minDate={new Date()}
                  maxDate={maxDate.setDate(maxDate.getDate() + 180)}
                />
              </Box>
            )}
          </Stack>
        </Stack>

        <Container>
          {" "}
          {/*  offers cards*/}
          <YORHeadingContent
            heading={yorCmsData?.section2?.title}
            content={
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {yorCmsData?.section2?.offerCards?.map((item: any) => {
                    return (
                      <Grid
                        size={{ xs: 12, sm: 12, md: 3 }}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <OfferCard data={item} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            }
          />
          {/* yor opt reasons */}
          <YORHeadingContent
            heading={yorCmsData?.section3?.title}
            content={
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {yorCmsData?.section3?.reasonCards?.map((reason: any) => {
                    return (
                      <Grid sx={{ display: "flex", justifyContent: "center" }}>
                        <Card
                          className={Styles.optReasonCard}
                          sx={{
                            backgroundImage: `url(${reason?.backgroundImage})`,
                            border: `1px solid ${
                              reason?.borderColor
                                ? reason?.borderColor
                                : colors.primary
                            }`,
                          }}
                        >
                          <Stack
                            height={"100%"}
                            justifyContent={"space-between"}
                          >
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "contain",
                              }}
                              src={reason?.image}
                            />
                            <Typography
                              variant={TypographyConstants.BODY1}
                              sx={{
                                fontWeight: 500,
                                color: colors.secondary_green,
                              }}
                            >
                              {reason.heading}
                            </Typography>
                          </Stack>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            }
          />
          {/* get started with yor */}
          <YORHeadingContent
            heading={yorCmsData?.section4?.title}
            content={
              <Box>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Grid
                      container
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      {yorCmsData?.section4?.getStartedCards?.map(
                        (item: any, index: number) => {
                          return (
                            <Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "8px",
                                    height: "23px",
                                    backgroundColor:
                                      index === 0 // need to change to 1 after changes from backend
                                        ? colors.primary
                                        : colors.white,
                                    borderRadius: "8px",
                                  }}
                                />

                                <Box
                                  className={Styles.getStartedYorBox}
                                  sx={{
                                    backgroundColor:
                                      index === 0 // need to change to 1 after changes from backend
                                        ? colors.dark_desaturated_cyan
                                        : colors.white,
                                  }}
                                >
                                  <Box className={Styles.alignJustifyCenter}>
                                    <img
                                      style={{
                                        height: "34px",
                                        width: "34px",
                                        objectFit: "contain",
                                      }}
                                      src={item?.image}
                                    />
                                  </Box>
                                  <Stack>
                                    <Typography
                                      variant={TypographyConstants.BODY1}
                                      sx={{
                                        fontWeight: 500,
                                        color:
                                          index === 0 // need to change to 1 after changes from backend
                                            ? colors.white
                                            : colors.very_dark_gray,
                                      }}
                                    >
                                      {item?.heading}
                                    </Typography>
                                    <Typography
                                      variant={TypographyConstants.BODY1}
                                      sx={{
                                        color:
                                          index === 0 // need to change to 1 after changes from backend
                                            ? colors.very_light_gray
                                            : "#888888",
                                      }}
                                    >
                                      {item?.subHeading}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                  <Grid size={{ md: 6 }} display={{ xs: "none", md: "flex" }}>
                    <img
                      style={{
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={yorCmsData?.section4?.mainImage ?? XplulseImage}
                    />
                  </Grid>
                </Grid>
              </Box>
            }
          />
          {/* why subscription is better */}
          <YORHeadingContent
            heading={yorCmsData?.section5?.title}
            content={
              <Stack sx={{ maxWidth: "800px", gap: "10px" }}>
                <Grid container columnSpacing={2}>
                  <>
                    <Grid
                      size={7}
                      minHeight={"40px"}
                      sx={{
                        backgroundColor: colors.white,
                        display: "flex",
                        alignItems: "center",
                        padding: "0px 16px",
                      }}
                    >
                      <Typography
                        variant={TypographyConstants.BODY1}
                        sx={{ color: "#343434" }}
                      >
                        {""}
                      </Typography>
                    </Grid>
                    <Grid size={5}>
                      <Grid container minHeight={"50px"} columnSpacing={1}>
                        <Grid size={6}>
                          <Box
                            sx={{
                              height: "80%",
                              background:
                                "linear-gradient(0deg, #60D297, #60D297),linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              borderRadius: "5px 5px 0px 0px",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              variant={TypographyConstants.CAPTION}
                              sx={{ color: colors.white }}
                              fontSize={{ xs: "12px", md: "16px" }}
                            >
                              {yorCmsData?.section5?.tableHeading?.heading1}
                            </Typography>
                          </Box>
                        </Grid>
                        {/* <Grid size={4}>
                        <Box
                          sx={{
                            height: "90%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "5px 5px 0px 0px",
                            background:
                              "linear-gradient(0deg, #60D297, #60D297),linear-gradient(89.92deg, #1BD8DA 1.67%, rgba(27, 196, 166, 0.95) 123.92%)",
                            boxShadow: "0px 0px 16px 0px #0000001A",
                            marginTop: "-10px",
                          }}
                        >
                          <Typography
                            variant={TypographyConstants.CAPTION}
                            sx={{ color: colors.white }}
                            fontSize={{ xs: "12px", md: "16px" }}
                          >
                            {yorCmsData?.section5?.tableHeading?.heading2}
                          </Typography>
                        </Box>
                      </Grid> */}
                        <Grid size={6}>
                          <Box
                            sx={{
                              height: "80%",
                              backgroundColor: colors.dark_cyan,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              borderRadius: "5px 5px 0px 0px",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              variant={TypographyConstants.CAPTION}
                              sx={{ color: colors.white }}
                              fontSize={{ xs: "12px", md: "16px" }}
                            >
                              {yorCmsData?.section5?.tableHeading?.heading2}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                  {yorCmsData?.section5?.tableEntries.map(
                    (item: any, index: number) => (
                      <>
                        <Grid
                          size={7}
                          minHeight={"40px"}
                          sx={{
                            backgroundColor:
                              index % 2 ? colors.white : "#F5F5F5",
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 16px",
                          }}
                        >
                          <Typography
                            variant={TypographyConstants.BODY1}
                            sx={{ color: "#343434" }}
                            fontSize={{ xs: "12px", md: "16px" }}
                          >
                            {item?.title}
                          </Typography>
                        </Grid>
                        <Grid size={5}>
                          <Grid container minHeight={"40px"}>
                            <Grid size={6}>
                              <Box
                                sx={{
                                  height: "100%",
                                  backgroundColor:
                                    colors.very_light_gray_secondary,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {iconsSection5[item?.heading1?.toLowerCase()]}
                              </Box>
                            </Grid>
                            {/* <Grid size={4}>
                            <Box
                              sx={{
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                background: colors.white,
                              }}
                            >
                              <Typography
                                variant={TypographyConstants.CAPTION}
                                fontSize={{ xs: "12px", md: "16px" }}
                              >
                                {item?.yorSubscription}
                              </Typography>
                            </Box>
                          </Grid> */}
                            <Grid size={6}>
                              <Box
                                sx={{
                                  height: "100%",
                                  backgroundColor:
                                    colors.very_light_gray_secondary,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  padding: "5px",
                                }}
                              >
                                {iconsSection5[item?.heading2?.toLowerCase()]}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                </Grid>

                {yorCmsData?.section5?.disclaimer ? (
                  <Box>
                    <Typography>{yourOwnRide.disclaimer}</Typography>
                    <Typography>{yorCmsData?.section5?.disclaimer}</Typography>
                  </Box>
                ) : null}
              </Stack>
            }
          />
          {/* testimonials section web and large screen */}
          <Box className={Styles.customerTestimonials}>
            <YORHeadingContent
              heading={yorCmsData?.section6?.title}
              padding={"0px"}
              gap="0px"
              content={
                <Carousel
                  animation="slide"
                  duration={500}
                  height={"400px"}
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  {divideArrayTo2D(yorCmsData?.section6?.testimonials, 4)?.map(
                    (item: any[]) => {
                      return (
                        <Box className={Styles.customerReviewBox}>
                          {item?.map((review: any, index: number) => {
                            return (
                              <CustomerReviewCard
                                data={review}
                                index={index}
                                isLength={item?.length === 4}
                              />
                            );
                          })}
                        </Box>
                      );
                    }
                  )}
                </Carousel>
              }
            />
          </Box>
          {/* testimonials section tab view  */}
          <Box className={Styles.customerTestimonialsTab}>
            <YORHeadingContent
              heading={yorCmsData?.section6?.title}
              padding={"0px"}
              content={
                <Carousel
                  animation="slide"
                  duration={500}
                  height={"310px"}
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                  }}
                  stopAutoPlayOnHover={true}
                  swipe={true}
                >
                  {divideArrayTo2D(yorCmsData?.section6?.testimonials, 2)?.map(
                    (item: any[]) => {
                      return (
                        <Box className={Styles.customerReviewBox}>
                          {item?.map((review: any, index: number) => {
                            return (
                              <CustomerReviewCard
                                data={review}
                                index={index}
                                isLength={item?.length === 4}
                              />
                            );
                          })}
                        </Box>
                      );
                    }
                  )}
                </Carousel>
              }
            />
          </Box>
          {/* testimonials section mobile view  */}
          <Box className={Styles.customerTestimonialsMobile}>
            <YORHeadingContent
              heading={yorCmsData?.section6?.title}
              padding={"0px"}
              content={
                <Carousel
                  animation="slide"
                  duration={500}
                  height={"305px"}
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                  }}
                  stopAutoPlayOnHover={true}
                >
                  {yorCmsData?.section6?.testimonials?.map(
                    (item: any, index: number) => {
                      return (
                        <Box className={Styles.customerReviewBox}>
                          <CustomerReviewCard
                            data={item}
                            index={index}
                            isLength={item?.length === 4}
                          />
                        </Box>
                      );
                    }
                  )}
                </Carousel>
              }
            />
          </Box>
          {/* faqs section */}
          <YORHeadingContent
            heading={yorCmsData?.section7?.title}
            content={
              <Stack className={Styles.faqsBox}>
                {yorCmsData?.section7?.faqs?.map((item: any) => {
                  return (
                    <CustomAccordion
                      headerText={item.question}
                      customStyle={{
                        backgroundColor: colors.light_grayish_cyan,
                        width: "90%",
                        boxShadow: "none",
                        borderRadius: 0,
                      }}
                    >
                      <Typography
                        variant={TypographyConstants.BODY1}
                        sx={{ color: "#8B8B8B" }}
                      >
                        {item.answer}
                      </Typography>
                    </CustomAccordion>
                  );
                })}
              </Stack>
            }
          />
        </Container>

        {/* select yor city modal */}
        <SelectCityModalYor
          cityList={cityV2}
          handleCityClick={handleCityClick}
          openModal={showCityModal}
          handleClose={cityModalToggleHandler}
        />
      </Stack>
    </Fragment>
  );
};

export default YorHomescreen;
