import HomeScreen from "../screen/homeScreen";
import CheckoutScreen from "../screen/checkoutScreen";
import AboutScreen from "../screen/about";
import BlogScreen from "../screen/blog";
import BlogDetails from "../screen/blog/blogDetail";
import ContactScreen from "../screen/contact";
import FAQScreen from "../screen/faq";
import RentScreen from "../screen/rent";
import SettingScreen from "../screen/setting";
import Profile from "../screen/setting/myBookings";
import { Routes } from "../constant/routeContants";
import BookingDetails from "../screen/setting/myBookings/BookingDetails";
import CityFreedo from "../screen/city-freedo/CityFreedo";
import TermsAndConditions from "../screen/termsConditions";
import PrivacyPolicies from "../screen/privacyPolicies";
import PaymentSuccess from "../screen/checkoutScreen/PaymentSuccess";
import FreedoBusiness from "../screen/freedoBusiness";
import Mitc from "../screen/mitc";
import ThankyouPage from "../component/ThankyouPage";
import PaymentPending from "../screen/checkoutScreen/PaymentPending";
import DeleteAccount from "../component/DeleteAccount";
import Feedback from "../screen/setting/myBookings/feedback";
import YORPlanDetails from "../yor-src/screens/PlanDetails";
import YorHomescreen from "../yor-src/screens/YorHomeScreen";
import YorRentingFleet from "../yor-src/screens/YorRentingFleet";
import OperatorRegistration from "../screen/OperatorManagement/OperatorRegistration";
import OperatorVerification from "../screen/OperatorManagement/OperatorVerification";

export const unauthRoutes = [
  {
    path: Routes.HOME,
    component: <HomeScreen />,
  },
  {
    path: Routes.ABOUT_US,
    component: <AboutScreen />,
  },
  {
    path: Routes.CONTACT,
    component: <ContactScreen />,
  },
  {
    path: Routes.FAQ,
    component: <FAQScreen />,
  },
  {
    path: Routes.RENT,
    component: <RentScreen />,
  },
  {
    path: Routes.BLOG,
    component: <BlogScreen />,
  },
  {
    path: Routes.BLOG_DETAILS,
    component: <BlogDetails />,
  },
  {
    path: Routes.TERMS_CONDITIONS,
    component: <TermsAndConditions />,
  },
  {
    path: Routes.TERMS_CONDITIONS_APP,
    component: <TermsAndConditions />,
  },
  {
    path: Routes.PRIVACY_POLICIE,
    component: <PrivacyPolicies />,
  },
  {
    path: Routes.BANGALORE,
    component: <CityFreedo />,
  },
  {
    path: Routes.GURGAON,
    component: <CityFreedo />,
  },
  {
    path: Routes.JAIPUR,
    component: <CityFreedo />,
  },
  {
    path: Routes.VADODARA,
    component: <CityFreedo />,
  },
  {
    path: Routes.DELHI,
    component: <CityFreedo />,
  },
  {
    path: Routes.NOIDA,
    component: <CityFreedo />,
  },
  {
    path: Routes.GHAZIABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.FARIDABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.VIJAYAWADA,
    component: <CityFreedo />,
  },
  {
    path: Routes.HYDERABAD,
    component: <CityFreedo />,
  },
  {
    path: Routes.KOLKATA,
    component: <CityFreedo />,
  },
  {
    path: Routes.FREEDO_BUSINESS,
    component: <FreedoBusiness />,
  },
  {
    path: Routes.MITC_FORM,
    component: <Mitc />,
  },
  {
    path: Routes?.THANKYOU_PAGE,
    component: <ThankyouPage />,
  },
  {
    path: Routes?.DELETE_ACCOUNT,
    component: <DeleteAccount />,
  },
  {
    path: Routes?.FEEDBACK,
    component: <Feedback />,
  },
  {
    path: Routes?.GOA,
    component: <CityFreedo />,
  },
  {
    path: Routes?.LUCKNOW,
    component: <CityFreedo />,
  },
  { path: Routes?.YOR_HOME_SCREEN, component: <YorHomescreen /> },
  { path: Routes?.YOR_RENTING_FLEET, component: <YorRentingFleet /> },
  {
    path: Routes?.YOR_PLAN_DETAILS,
    component: <YORPlanDetails />,
  },
  {
    path: Routes?.OPERATOR_REGISTRATION,
    component: <OperatorRegistration />,
  },
  {
    path: Routes?.OPERATOR_EMAIL_VERIFICATION,
    component: <OperatorVerification />,
  },
  {
    path: Routes?.OPERATOR_ESIGN_VERIFICATION,
    component: <OperatorVerification />,
  },
];

export const customRoutes = [
  {
    path: Routes.CHECKOUT,
    component: <CheckoutScreen />,
  },
  {
    path: Routes.PROFILE,
    component: <SettingScreen />,
  },
  {
    path: Routes.BOOKING_DETAILS,
    component: <BookingDetails />,
  },
  {
    path: Routes.PAYMENT_SUCCESS,
    component: <PaymentSuccess />,
  },
  {
    path: Routes.PAYMENT_PENDING,
    component: <PaymentPending />,
  },
];
